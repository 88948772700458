'use client';

import Script from 'next/script';

const GoogleTagManager = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <>
      {/* Google Tag Manager */}
      <Script id="google-tag-manager" strategy="lazyOnload">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PBJXRR64');
        `}
      </Script>

      {/* Google Ads */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-16553565883"
        strategy="lazyOnload"
      />

      <Script id="google-ads-gtag" strategy="lazyOnload">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'AW-16553565883');
        `}
      </Script>

      {/* Event snippet for conversion */}
      {!isDevelopment && (
        <Script id="page-view-gtag" strategy="lazyOnload">
          {`gtag('event', 'conversion', {
          'send_to': 'AW-16553565883/LW5_CMiH26wZELu9rdU9',
          'value': 0.01,
          'currency': 'USD'
          });`}
        </Script>
      )}

      {isDevelopment && (
        <Script id="page-view-gtag-dev" strategy="lazyOnload">
          {`console.log('Development mode: conversion event not sent to avoid charges.');`}
        </Script>
      )}
    </>
  );
};

export default GoogleTagManager;
